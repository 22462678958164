import React from 'react'
import { Container, Row, Alert } from 'react-bootstrap'

import Layout from '../Layout'

export default () => {
    return <Layout>
        <Container>
        <Row>
            {[
                'primary',
                'secondary',
                'success',
                'danger',
                'warning',
                'info',
                'light',
                'dark',
            ].map((variant, idx) => (
                <Alert key={idx} variant={variant}>
                    This is a {variant} alert—check it out!
                </Alert>
            ))}
        </Row>
    </Container></Layout>
}